<template>
  <v-app>
    <Snackbar />
    <v-navigation-drawer v-model="drawer" app clipped v-if="isAutenticado">
      <v-list dense>
        <v-list-item @click="">
          <v-list-item-action>
            <v-icon>home</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="">
          <v-list-item-action>
            <v-icon>contact_mail</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Contact</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>

        <div class="pa-1">
          <v-list dense>
            <v-list-item>
              <v-list-item-title class="text-center font-weight-medium">Versão 1.0</v-list-item-title>
            </v-list-item>
          </v-list>
          <v-btn @click="logout" dark block>Sair</v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-app-bar app color="primary" dark clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="isAutenticado"/>
      <span class="title ml-5 mr-5">Central&nbsp;<span class="font-weight-light">Aprova</span></span>
      <!--<div class="d-flex align-center">
        <v-img alt="Logo" class="shrink mr-2" contain :src="require('@/assets/logo.png')" transition="scale-transition" width="80"/>
        <!--<v-img alt="Logo" class="shrink mr-2 hidden-sm-and-down" contain :src="require('@/assets/logo.png')" transition="scale-transition" width="50"/>-->
        <!--<v-toolbar-title>ARCA</v-toolbar-title>
      </div> -->
      <v-spacer/>
    </v-app-bar>

    <v-content>
      <Load/>
      <router-view/>
    </v-content>
  </v-app>
</template>

<script>
import Load from "@core/components/Load.vue";
import Snackbar from "@core/components/SnackBar.vue";


export default {
  name: "App",

  components: {
    Load,
    Snackbar
  },

  data() {
    return {
      drawer: null,
    };
  },

  methods: {
    logout(){
      this.$store.dispatch("application/logout");
      this.$router.push({ name: "login" });
    }
  },

  computed: {
    isAutenticado() {
      return this.$store.getters["application/isAutenticado"];
    }
  },

};
</script>
